import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'
import Footer from '../components/Footer'
import Flow from '../components/Flow'
import InViewSection from '../components/InViewSection'
import RichText from '../components/RichText'
import Container from '../components/_layouts/Container'
import Grid from '../components/_layouts/Grid'
import GridItem from '../components/_layouts/GridItem'
import { Heading2 } from '../components/TextStyles'
import ScrollSection from '../components/ScrollSection'

const PrivacyPolicyPage = ({ data }) => (
  <>
    <Seo title="Privacy Policy" />

    <ScrollSection>
      <Container>
        <Grid>
          <GridItem tabletP={4} tabletPStart={2}>
            <Spacer size={[150, 300]} />

            <Heading2>Privacy Policy</Heading2>
          </GridItem>

          <GridItem tabletP={6} tabletPStart={2} deskL={4} deskLStart={3}>
            <Spacer size={[20, 80]} />

            <InViewSection threshold={0}>
              <Flow>
                <RichText
                  content={data.allContentfulPagePrivacyPolicy.nodes[0].content}
                  textSize="small"
                  animate={false}
                />
              </Flow>
            </InViewSection>
          </GridItem>
        </Grid>
      </Container>
    </ScrollSection>

    <ScrollSection>
      <Footer />
    </ScrollSection>
  </>
)

export default PrivacyPolicyPage

export const pageQuery = graphql`
  query {
    allContentfulPagePrivacyPolicy {
      nodes {
        content {
          raw
        }
      }
    }
  }
`
